.CompanyProfile{
    height: 1080px;
    padding-top: 120px;
    padding-bottom: 42px;
    background-color: white;
    .title{
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101113;
        line-height: 50px;
        letter-spacing: 1px;
    }
    .btn{
        width: 226px;
        height: 54px;
        background: #004CFF;
        box-shadow: 0px 3px 5px 0px rgba(154,184,255,1);
        border-radius: 27px;
    }
    .company{
        font-size: 12px;
        font-family: HiraginoSansGB-W3, HiraginoSansGB;
        font-weight: normal;
        color: #6F6F70;
        line-height: 18px;
        letter-spacing: 3px;
    }
    .divfont{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404042;
        line-height: 36px;
        letter-spacing: 1px;
        row-gap: 0px;
        text-align: left;
        padding: 0 22%;
        text-align: center;
    }
    :global {

        .ant-row{
            justify-content: center;

        }
        .ant-btn > span {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            letter-spacing: 4px;
        }
        
    }
    
}