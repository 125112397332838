.CompanyProfile{
    height: 100vh;
    padding-top: 120px;
    background-color: #f8f8f9;
    .title{
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101113;
        line-height: 50px;
        letter-spacing: 1px;
    }
    .btn{
        width: 226px;
        height: 54px;
        background: #004CFF;
        box-shadow: 0px 3px 5px 0px rgba(154,184,255,1);
        border-radius: 27px;
    }
    .listt{
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #404042;
        line-height: 25px;
        letter-spacing: 1px;
        margin: 30px 0 12px 0;
    }
    .listc{
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404042;
        line-height: 25px;
        letter-spacing: 1px;
        word-wrap: break-word;
        word-break: normal;
        width: 300px;
        text-align: left;
    }



    .contentTitle{
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #004CFF;
        line-height: 33px;
        letter-spacing: 1px;
    }

    .contentTitleone{
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101113;
        line-height: 33px;
        letter-spacing: 1px;
    }
    .number{
        padding-right: 30px;
        text-align: end;
    }
    .content{
        word-break: normal;
        width: 95%;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404042;
        line-height: 25px;
        letter-spacing: 1px;
        text-align: left;
    }
    .company{
        font-size: 12px;
    font-family: HiraginoSansGB-W3, HiraginoSansGB;
    font-weight: normal;
    color: #6F6F70;
    line-height: 18px;
    letter-spacing: 3px;
    }
    .divfont{
        font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #404042;
    line-height: 36px;
    letter-spacing: 5px;
    row-gap: 0px;
    }
    .rightC{
        width: 486px;
        height: 768px;
        background-color: #9AB8FF;
    }

    .contentStyle {
        height: 484px;
        color: #fff;
        line-height: 160px;
        text-align: center;
        background: #364d7e;
    }
    .spanE{
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 50px;
        letter-spacing: 7px;
    }
    :global {
        .ant-btn > span {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            letter-spacing: 4px;
        }
        .ant-carousel .slick-dots-bottom{
            position:absolute;
            top: 550px;
            justify-content:start;
            margin-left: 30px;
        }
        .ant-carousel .slick-dots li{
            width: 40px;
        }

        .ant-carousel .slick-dots li.slick-active{
            width: 40px;
        }
        
    }
    
}