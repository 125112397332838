.ProductIntroduction{
    height: 82vh;
    padding-top: 120px;
    background-color: #fff;
    .btn{
        width: 226px;
        height: 54px;
        background: #004CFF;
        box-shadow: 0px 3px 5px 0px rgba(154,184,255,1);
        border-radius: 27px;
    }
    .listt{
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #404042;
        line-height: 25px;
        letter-spacing: 3px;
    }
    .listc{
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404042;
        line-height: 25px;
        letter-spacing: 2px;
    }

    .title{
        font-size: 48px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101113;
        line-height: 67px;
        letter-spacing: 13px;
    }

    .top{
        font-size: 48px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101113;
        line-height: 67px;
        letter-spacing: 13px;
    }

    .word{
        word-wrap: break-word;
        width: 510px;
        word-break: normal;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 25px;
        letter-spacing: 1px;
        text-align: left;
    }
    .jqr{
        height: 84px;
        font-size: 60px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101113;
        line-height: 84px;
        letter-spacing: 1px;
    }
    .jqrcontent{
        width: 589px;
        height: 100px;
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 50px;
        letter-spacing: 1px;
        margin-top: 30px;
        margin-bottom: 90px;
        text-align: left;
    }

    .gcj{
        width: 408px;
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 50px;
        text-align: right;
        letter-spacing: 1px;
    }

    .jqra{
        height: 33px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #404042;
        line-height: 33px;
        letter-spacing: 1px;
        padding: 30px 140px 90px;
    }

    .sheng{
        height: 40px;
        font-size: 36px;
        font-family: Jyinbikai;
        color: #000000;
        line-height: 40px;
        letter-spacing: 2px;
    }
    :global {
        .ant-btn > span {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            letter-spacing: 4px;
        }
        .ant-carousel .slick-dots-bottom{
            position:absolute;
            top: 550px;
            justify-content:start;
            margin-left: 30px;
        }
        .ant-carousel .slick-dots li{
            width: 40px;
        }

        .ant-carousel .slick-dots li.slick-active{
            width: 40px;
        }
        
    }
    
}