.HomePageCntent{
    margin-top: 90px;
    .Header {
        height: 90px;
        background: #FFFFFF;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    }
    :global {
        .ant-layout-header{
            background-color: white;
            padding: 0 20%;
            min-width: 1200px;
        }
    }
    .toplist{
      position: fixed;
      width: 100%;
      z-index: 1;
    }

    .cursor{
      cursor:pointer;
    }
    .scroll{
        width: 100%;
        // height: 900px;
        line-height: 50px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 30px;
        padding: 200px 400px 40px;
      }
      .scrollall {
        width: 100%;
        // height: 900px;
        line-height: 50px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 30px;
        padding: 20px 400px 20px;
      }

      .scrollalla {
        width: 100%;
        // height: 900px;
        line-height: 50px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 30px;
        padding: 20px 400px 20px;
      }
      .scrollone{
        width: 100%;
        // height: 900px;
        line-height: 50px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 30px;
        padding: 200px 400px 20px;
        color: white;

      }
      .scrollall span{
        // display: inline-block;/*inline样式不能使用动画*/
        animation: scroll 1s linear infinite;
        color: white;    
      }
      .scroll div{
        // display: inline-block;/*inline样式不能使用动画*/
        animation: scroll 1s linear infinite;
        color: white;
        width: 900px;
        height: 208px;
      }

      .showspan{
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        letter-spacing: 45px;       
      }
      
      .scrollone div{
        // display: inline-block;/*inline样式不能使用动画*/
        color: white;
        width: 900px;
        height: 208px;
      }
      @keyframes scroll {
        from {
          transform: translateY(20px)
        }
        to {
          transform: translateY(2%)
        }
      }

      @keyframes scrollall {
        from {
          transform: translateY(20px)
        }
        to {
          transform: translateY(1%)
        }
      }

      .cpjs{
        font-size: 60px;
        font-family: TimesNewRomanPS-BoldMT, TimesNewRomanPS;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 69px;
        padding-top: 157px;
        letter-spacing: 3px;
      } 

      .cpjsone{
        height: 50px;
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 50px;
        letter-spacing: 7px;
      }
}