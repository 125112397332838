.JoinUs{
    height: 82vh;
    padding-top: 120px;
    background-color: #fff;
    .btn{
        width: 226px;
        height: 54px;
        background: #004CFF;
        box-shadow: 0px 3px 5px 0px rgba(154,184,255,1);
        border-radius: 27px;
    }
    .listt{
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #404042;
        line-height: 25px;
        letter-spacing: 3px;
    }
    .listc{
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404042;
        line-height: 25px;
        letter-spacing: 2px;
    }

    .title{
        width: 173px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101113;
        line-height: 25px;
        letter-spacing: 1px;
    }

    .top{
        font-size: 48px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101113;
        line-height: 67px;
        letter-spacing: 13px;
    }

    .word{
        width: 612px;
        height: 58px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404042;
        line-height: 25px;
        letter-spacing: 1px;
        text-align: left;
    }
    .post{
        background-color: #004CFF;
        color: white;
        position: absolute;
        height: 173px;
        width: 266px;
        z-index: 1;
        right: 0;
        top: 135px;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 50px;
        letter-spacing: 1px;
        padding-right: 30px;
    }
    .btlin{
        border-bottom: 1px solid #E7E7E7;
        margin: 28px 0;
    }

    .year{
        row-gap: 0px;
        height: 168px;
        font-size: 120px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #EA2F02;
        line-height: 168px;
    }
    .listspan{
        word-wrap: break-word;
        word-break: normal;
        max-width: 500px;
        text-align: left;
        padding: 5px 0 0 18px;
    }
    .xuan{

        height: 50px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #6F6F70;
        line-height: 50px;
        letter-spacing: 1px;
        padding-top: 75px;
    }

    .spana{
        height: 33px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #6F6F70;
        line-height: 33px;
        letter-spacing: 1px;

    }
    .nf{
        font-size: 180px;
        font-family: LiGothicMed;
        color: #E7E7E7;
        line-height: 120px;
        letter-spacing: 16px;
    }
    .list{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #101113;
        line-height: 25px;
        letter-spacing: 1px;
        margin-bottom: 28px;
    }
    .btlins{
        border-bottom: 1px solid #9E9FA0;
        margin: 50px 0 30px 0;

    }

    .lin{
        display: inline-block;
        border-left: 10px solid #004CFF;
        height: 45px;
        margin-right: 15px;
    }
    .jqr{
        width: 250px;
        height: 66px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #004CFF;
        line-height: 33px;
        letter-spacing: 6px;
    }
    .jqrcontent{
        padding-left: 360px;
        height: 50px;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101113;
        line-height: 50px;
        letter-spacing: 1px;
    }

    .con{
        width: 996px;
        height: 577px;
        background: #FFFFFF;
        padding: 80px 100px 120px 120px;
    }

    .bbspan{
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101113;
        line-height: 25px;
        letter-spacing: 1px;
    }

    .name{
        height: 67px;
        font-size: 48px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101113;
        line-height: 67px;
        letter-spacing: 2px;
    }

    .zw{
        height: 33px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 33px;
        letter-spacing: 1px;
    }
    .font {
        font-size: 17px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #101113;
        line-height: 30px;
        letter-spacing: 1px;
        text-align: left;
    }
    .img{
        position: absolute;
        left: 11%;
        top: 325px;;
    }

    .hengx{
        width: 50px;
    border-bottom: 2px solid;
    margin: 40px 0;
    }
    .gcj{
        width: 368px;
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 50px;
        letter-spacing: 9px;
    }
    :global {
        .ant-btn > span {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            letter-spacing: 4px;
        }
        .ant-carousel .slick-dots-bottom{
            position:absolute;
            top: 550px;
            justify-content:start;
            margin-left: 30px;
        }
        .ant-carousel .slick-dots li{
            width: 40px;
        }

        .ant-carousel .slick-dots li.slick-active{
            width: 40px;
        }
        
    }
    
}