.Footer{
    height: 380px;
    background-color: #404042;
    padding: 65px 13%;
    color: #fff;
    width: 100%;
    text-align: left;
    .Header {
        height: 90px;
        background: #FFFFFF;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid #e8e8e9;
    }

    .Footer{
        height: 216px;
        padding: 30px 355px;
        background: #404042;
    }    
    .FooterRow{
        height: 2px;
        padding-top: 15px;
    }
    .tabs{
        height: 90px;
    }
    .TabPane{
        height: 100%;
    }
    .span{
        display: inline-block;
        height: 10px;
        border-left: 1px solid #E7E7E7;
        margin: 0 8px;
        width: 1px;
        height: 12px;
    }
    .foot{
        border-bottom: 1px solid #fff;
        padding-bottom: 50px;
    }
    .title{
        font-size: 21px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 29px;
        letter-spacing: 1px;
    }
    .font{
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 17px;
        letter-spacing: 3px;
    }
    a {
        color: #fff;
    }
    :global {
        .ant-layout-footer{
        }

        .ant-tabs-tab-btn{
            outline: none;
            transition: all 0.3s;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
        }
        .ant-tabs > .ant-tabs-nav{
            height: 100%;
            margin: 0;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #004CFF;
        }
        .ant-btn{
            border: none;
            padding: 0;
        }
        
    }
    
}

