.JoinUs{
    height: 82vh;
    padding-top: 120px;
    background-color: #fff;
    .btn{
        width: 226px;
        height: 54px;
        background: #004CFF;
        box-shadow: 0px 3px 5px 0px rgba(154,184,255,1);
        border-radius: 27px;
    }
    .listt{
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #404042;
        line-height: 25px;
        letter-spacing: 3px;
    }
    .listc{
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404042;
        line-height: 25px;
        letter-spacing: 2px;
    }

    .title{
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101113;
        line-height: 25px;
        letter-spacing: 1px;
    }

    .top{
        font-size: 48px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101113;
        line-height: 67px;
        letter-spacing: 13px;
    }

    .word{
        width: 432px;
        height: 58px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404042;
        line-height: 25px;
        letter-spacing: 1px;
        margin-bottom: 30px;
        margin-top: 10px;
    }
    .jqr{
        width: 302px;
        height: 66px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #004CFF;
        line-height: 33px;
        letter-spacing: 1px;
    }
    .jqrcontent{
        height: 100px;
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 50px;
        letter-spacing: 1px;
    }
    .zhaop{
        background-color: white;
        padding: 30px;
        margin-bottom: 30px;
        .font{
            height: 33px;
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #101113;
            line-height: 33px;
            letter-spacing: 1px;
        }
        .moreInformation{
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3370FF;
            line-height: 26px;
            letter-spacing: 1px;
        }
        .my{
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #EA2F02;
            line-height: 25px;
            letter-spacing: 1px;
        }
        .bt{
            border-top: 1px solid #E7E7E7;
            padding-top: 30px;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
            margin-top: 30px;
            text-align: left;
        }
        
    }
    .jn{
        display: inline-block;
        border-radius: 6px;
        border: 1px solid #9E9FA0;
        color: #9E9FA0;
        padding: 10px 8px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9E9FA0;
        line-height: 12px;
        margin-right: 20px;
    }
    .gcj{
        width: 368px;
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 50px;
        letter-spacing: 9px;
    }
    :global {
        .ant-btn > span {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            letter-spacing: 4px;
        }
        .ant-carousel .slick-dots-bottom{
            position:absolute;
            top: 550px;
            justify-content:start;
            margin-left: 30px;
        }
        .ant-carousel .slick-dots li{
            width: 40px;
        }

        .ant-carousel .slick-dots li.slick-active{
            width: 40px;
        }
        
    }
    
}