.Home{
    height: 100vh;
    width: 100%;
    .Header {
        height: 90px;
        background: #FFFFFF;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid #e8e8e9;
        position: fixed;
        min-width: 1000px;
        width: 100%;
        z-index: 9;
    }

    .Footer{
        height: 216px;
        padding: 30px 355px;
        background: #404042;
    }    
    .FooterRow{
        row-gap: 0px;
        background: rgba(255, 255, 255, 0.21);
        height: 2px;
    }
    .tabs{
        height: 90px;
    }
    .TabPane{
        height: 100%;
    }
    .span{
        display: inline-block;
        height: 10px;
        border-left: 1px solid #E7E7E7;
        margin: 0 8px;
        width: 1px;
        height: 12px;
    }
    .anchorpoint{
        top: 35vh;
        position: fixed;
        right: 10vw;
        display: inline-block;
    }
    .anchor{
        border: 1px solid #e7e7e7;
        margin-bottom: 40px;
        border-radius: 13px;
        height: 13px;
        width: 13px;
        background-color: #e7e7e7;
    }
    .anchorClick{
        border: 1px solid #404042;
        margin-bottom: 40px;
        border-radius: 20px;
        height: 20px;
        width: 20px;
        background-color: rgb(83, 82, 82);
        margin-left: -3px;
    }
    :global {

        .ant-tabs-tab-btn{
            outline: none;
            transition: all 0.3s;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
        }
        .ant-tabs > .ant-tabs-nav{
            height: 100%;
            margin: 0;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #004CFF;
        }
        .ant-btn{
            border: none;
            padding: 0;
        }
    }
    
}

